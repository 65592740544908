/* eslint-disable no-restricted-globals */
(() => {
  const staticCacheName = "{{version}}";

  const filesToCache = [".", "about", "offline"];

  const resourcePatterns = ["assets/"];

  self.addEventListener("install", (event) => {
    event.waitUntil(
      caches
        .open(staticCacheName)
        .then((cache) => cache.addAll(filesToCache))
        .then(() => self.skipWaiting())
    );
  });

  self.addEventListener("activate", (event) => {
    const cacheWhitelist = [staticCacheName];
    event.waitUntil(
      caches.keys().then((cacheNames) =>
        Promise.all(
          cacheNames.map((cacheName) => {
            if (cacheWhitelist.indexOf(cacheName) === -1) {
              return caches.delete(cacheName);
            }
            return false;
          })
        )
      )
    );
  });

  const networkRequestAndAddToCache = (event) =>
    fetch(event.request).then((response) =>
      caches.open(staticCacheName).then((cache) => {
        resourcePatterns.forEach((item) => {
          if (event.request.url.indexOf(item) > -1) {
            cache.put(event.request.url, response.clone());
          }
        });
        return response;
      })
    );

  self.addEventListener("fetch", (event) => {
    event.respondWith(
      caches
        .match(event.request)
        .then((response) => {
          if (response) {
            return response;
          }
          return networkRequestAndAddToCache(event);
        })
        .catch((error) => {
          /* eslint-disable-next-line no-console */
          console.log("Error, ", error);
          return caches.match("offline");
        })
    );
  });
})();
/* eslint-enable no-restricted-globals */
